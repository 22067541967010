import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import { dateFormat } from '@/Utils/fliter'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, circular, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        if (circular.org_id === 16) {
          pdfContent.push({
              table: {
                  style: 'tableData',
                  headerRows: 0,
                  widths: ['30%', '70%'],
                  body: [
                      [
                          { text: vm.$t('externalTraining.circular_memo_no'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.circular_memo_no : circular.circular_memo_no, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.fiscal_year : circular.fiscal_year_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('org_pro.organization'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.org : circular.org_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('org_pro.office_type'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.office_type : circular.office_type_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('org_pro.office_namel'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.office : circular.office_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.training_type : circular.training_type_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'bn' ? circular.training_category_bn : circular.training_category, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'bn' ? circular.training_title_bn : circular.training_title, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('globalTrans.district'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.getDistrict(circular.district_id), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('globalTrans.upazila'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.getUpazila(circular.upazila_id), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.other_trainee_type'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.getOtherTraineeType(circular.other_trainee_type_id), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_iabm.training_start_date'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: dateFormat(circular.training_start_date), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_iabm.training_end_date'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: dateFormat(circular.training_end_date), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.remarks'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'bn' ? circular.remarks_bn : circular.remarks, style: 'td', alignment: 'left' }
                      ]
                  ]
              }
          })
        } else {
          pdfContent.push({
              table: {
                  style: 'tableData',
                  headerRows: 0,
                  widths: ['30%', '70%'],
                  body: [
                      [
                          { text: vm.$t('externalTraining.circular_memo_no'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.circular_memo_no : circular.circular_memo_no, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.fiscal_year : circular.fiscal_year_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('org_pro.organization'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.org : circular.org_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('org_pro.office_type'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.office_type : circular.office_type_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('org_pro.office_namel'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.office : circular.office_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'en' ? circular.training_type : circular.training_type_bn, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'bn' ? circular.training_category_bn : circular.training_category, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'bn' ? circular.training_title_bn : circular.training_title, style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_iabm.batch_no'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$n(circular.batch_no), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_iabm.training_start_date'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: dateFormat(circular.training_start_date), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_iabm.training_end_date'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: dateFormat(circular.training_end_date), style: 'td', alignment: 'left' }
                      ],
                      [
                          { text: vm.$t('elearning_config.remarks'), style: 'th', alignment: 'left' },
                          // { text: ':', style: 'th', alignment: 'center' },
                          { text: vm.$i18n.locale === 'bn' ? circular.remarks_bn : circular.remarks, style: 'td', alignment: 'left' }
                      ]
                  ]
              }
          })
        }
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 11,
            bold: true,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 13,
            bold: true,
            margin: [0, -25, 10, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 14,
            bold: true,
            margin: [10, 10, 25, 20]
        },
          header2: {
            fontSize: 10,
            margin: [0, 10, 0, 10]
          },
          fertilizer: {
            margin: [0, 7, 0, 7]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('circular-publication-list')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
