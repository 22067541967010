<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            <div class="titleDiv">
                                <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('elearning_iabm.circular_info') }}</span><br>
                            </div>
                        </list-report-head>
                    </b-col>
                    </b-row>
                    <b-col lg="12">
                        <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                            <b-table-simple bordered>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.circular_memo_no }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.fiscal_year_bn : circular.fiscal_year }}</b-td>
                                </b-tr>
                                <b-tr v-if="$store.state.Auth.activeRoleId === 1">
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('org_pro.organization') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? circular.org_bn : circular.org }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('org_pro.office_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.office_type_bn : circular.office_type }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('org_pro.office_namel') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? circular.office_bn : circular.office }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.training_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.training_type_bn : circular.training_type }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.training_category') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? circular.training_category_bn : circular.training_category }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.training_title') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.training_title_bn : circular.training_title }}</b-td>
                                </b-tr>
                                <b-tr v-if="circular.org_id !== 16">
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_iabm.batch_no') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.batch_name_bn : circular.batch_name }}</b-td>
                                </b-tr>
                                <b-tr v-if="circular.org_id === 16">
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.other_trainee_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ getOtherTraineeType(circular.other_trainee_type_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.total_no_of_participant') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">
                                      <slot v-if="circular.total_no_of_participant">{{ $n(circular.total_no_of_participant) }}</slot>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.training_start_date') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.training_start_date | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr v-if="circular.org_id === 16">
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('globalTrans.district') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getDistrict(circular.district_id) }}</b-td>
                                </b-tr>
                                <b-tr v-if="circular.org_id === 16">
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('globalTrans.upazila') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getUpazila(circular.upazila_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.no_of_days') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">
                                      <slot v-if="circular.no_of_days">{{ $n(circular.no_of_days) }}</slot>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_iabm.training_end_date') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ circular.training_end_date | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.remarks') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? circular.remarks_bn : circular.remarks }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{$t('globalTrans.attachment')}}</b-th>
                                    <b-td style="width:65%%;" class="text-left">
                                    <b v-if="circular.upload_guidelines">
                                        <!-- <img :src="trainingElearningServiceBaseUrl + 'storage/uploads/circular-publications/' + circular.upload_guidelines" style="width: 190px; display: block; margin: 0 auto; height: 120px; border-radius: 0px !important;"> -->
                                        <a target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/circular-publications/' + circular.upload_guidelines" download>{{$t('globalTrans.view_download')}}</a>
                                        <!-- (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/circular-publications/' + circular.upload_guidelines" download>{{$t('globalTrans.view_download')}}</a> ) -->
                                    </b>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-col>
                        </b-row>
                          <b-overlay :show="officerLoading">
                            <b-row id="form"  v-if="circular.org_id === 12 && circular.coordinator_details">
                              <div class="col-md-12">
                                <fieldset class="p-2 w-100">
                                  <legend class="px-2 w-50 shadow-sm">{{$t('elearning_config.training_coordinator')}} {{$t('globalTrans.details')}}</legend>
                                  <table class="table" style="width:100%;padding: 5px">
                                    <thead class="thead">
                                      <tr>
                                        <th style="width:40%" class="text-center">{{ $t('globalTrans.designation') }}</th>
                                        <th style="width:60%" class="text-center">{{$t('elearning_config.training_coordinator')}} </th>
                                      </tr>
                                    </thead>
                                    <tr v-for="(item, index) in circular.coordinator_details" :key="index">
                                      <b-td class="text-center">
                                        {{ getDesignation(item.designation_id) }}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ getCoordinator(item.coordinator_id) }}
                                      </b-td>
                                    </tr>
                                  </table>
                                </fieldset>
                              </div>
                            </b-row>
                          </b-overlay>
                    </b-col>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 15px;
}
</style>
<script>
import ExportPdf from './export_pdf_details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { designationWiseUser } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    name: 'FormLayout',
    components: {
      ListReportHead
    },
    props: ['item'],
    created () {
        this.circular = this.item
        this.getOfficerList()
        this.getOtherTraineeType()
    },
    data () {
        return {
          officerLoading: false,
          circular: [],
          allOfficerList: [],
          slOffset: 1,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
        loading () {
        return this.$store.state.commonObj.loading
      }
    },
    methods: {
      getOtherTraineeType (orgTypeIds) {
        if (orgTypeIds !== null) {
          const arr = []
          if (orgTypeIds.constructor === Array) {
            orgTypeIds.forEach(item => {
            const orgTypeId = this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList.find(obj => obj.value === item)
            const orgTypeName = this.$i18n.locale === 'en' ? ' ' + orgTypeId.text_en : ' ' + orgTypeId.text_bn
            arr.push(orgTypeName)
            })
          }
          return arr.toString()
        }
      },
      getDistrict (id) {
        const office = this.$store.state.commonObj.districtList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
      },
      getDesignation (id) {
        const office = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
      },
      getCoordinator (id) {
        const officer = this.allOfficerList.find(item => parseInt(item.user_id) === parseInt(id))
        if (this.$i18n.locale === 'bn') {
            return officer !== undefined ? officer.name_bn : ''
        } else {
            return officer !== undefined ? officer.name : ''
        }
      },
      getUpazila (id) {
        const office = this.$store.state.commonObj.upazilaList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
      },
      async getOfficerList () {
        this.officerLoading = true
          const params = {
            org_id: parseInt(this.circular.org_id),
            office_type_id: parseInt(this.circular.office_type_id)
          }
          await RestApi.getData(trainingElearningServiceBaseUrl, designationWiseUser, params)
          .then(response => {
            if (response.success) {
              this.allOfficerList = response.data
            }
            this.officerLoading = false
        })
      },
      pdfExport () {
        const reportTitle = this.$t('elearning_iabm.circular_info')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.circular, this)
      }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  /* .reportTitle {
    font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
  } */
  /* .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  } */
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
